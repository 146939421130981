import client from './index'

import {
  ApiPaginationResponse,
  ApiResponse,
  PaginationParams,
} from '@/models/api'
import { Misconduct } from '@/models/misconduct'

export const getUserMisconductions = (
  userId: string,
  pagination: PaginationParams
) => {
  return client.get<ApiPaginationResponse<Misconduct>>('/misconducting', {
    params: { ...pagination, user_id: userId },
  })
}

export const createMisconduction = (userId: string, comment: string) => {
  return client.post<ApiResponse<Misconduct>>('/misconducting', {
    user_id: userId,
    comment,
  })
}
