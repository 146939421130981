import { onMounted, ref, UnwrapRef } from 'vue'

import { parseErrors } from '@/helpers/error-parsers'
import { showToast } from '@/helpers/messages'

export default function useApi<T, Q = string>(
  getResult: (query?: Q) => Promise<T>
) {
  const result = ref<T | null>(null)

  const loading = ref(true)
  const error = ref(false)
  const errorMessages = ref('')

  async function callAPI(q?: Q) {
    try {
      result.value = (await getResult(q)) as UnwrapRef<T>
    } catch (e) {
      const message = parseErrors(e).error
      showToast({ type: 'error', message })

      error.value = true
      error.value = true
      errorMessages.value = message
    } finally {
      loading.value = false
    }
  }

  return { result, loading, error, callAPI, errorMessages }
}
