// const apiKey = process.env.VUE_APP_GOOGLE_API_KEY

// if (!apiKey) {
//   console.warn('[GOOGLE API] process.env.VUE_APP_GOOGLE_API_KEY is missed')
// }

// const service = new google.maps.places.AutocompleteService()

// export const getCitiesAutocompletion = (
//   input: string,
//   code: string
// ): Promise<google.maps.places.AutocompleteResponse> => {
//   return service.getPlacePredictions({
//     input,
//     componentRestrictions: { country: code },
//   })
// }

const apiKey = process.env.VUE_APP_GOOGLE_API_KEY

if (!apiKey) {
  console.warn('[GOOGLE API] process.env.VUE_APP_GOOGLE_API_KEY is missed')
}

const service = new google.maps.places.AutocompleteService()

export const getCitiesAutocompletion = (
  input: string,
  code: string
): Promise<google.maps.places.AutocompletePrediction[]> => {
  return new Promise((resolve, reject) => {
    service.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: code },
      },
      (predictions, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          resolve(predictions)
        } else {
          reject(`Autocomplete service failed with status: ${status}`)
        }
      }
    )
  })
}
