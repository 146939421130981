import { ElMessage } from 'element-plus'
import { defineStore } from 'pinia'

import * as tripsApi from '@/api/trips'
import * as misconductionsApi from '@/api/misconductions'
import * as userApi from '@/api/user'
import vehiclesApi from '@/api/vehicles'

import { parseErrors } from '@/helpers/error-parsers'
import {
  ONGOING_STATUSES,
  PAST_RENTING_STATUSES,
  UPCOMING_STATUSES,
} from '@/constants/trips-statuses-groups'
import {
  getPagintaionModuleState,
  handleCommonModuleState,
  handleMultipleUpdatesState,
  handlePaginationModuleState,
} from '@/helpers/store-state'
import {
  getUpdatesErrorMsg,
  getUpdatesSuccessMsg,
  showToast,
} from '@/helpers/messages'

import { PaginationParams } from '@/models/api'
import { CommonModule } from '@/models/store'
import {
  UserType,
  UserUpdateableKeys,
  UpdateableUserParams,
  UserInsurancePolicy,
  UserInsuranceUpdatableKeys,
} from '@/models/user'

import {
  getInitialUsersState,
  getInitialUserDetailsState,
  getInitialUserTripsState,
  PapersVerifyingPayload,
  State,
  UpdateUserStatusPayload,
  GetUsersParams,
  UserTripsModule,
  getInitialMisconductsState,
  getInitialUserInsuranceDetailsState,
} from '.'

import { GetTripsParams, TripGroup } from '../trips'
import { GetVehiclesParams } from '../vehicles'
import { commonCase } from '@/helpers/strings'

export const useUsersStore = defineStore({
  id: 'users-store',
  state: getInitialUsersState,
  actions: {
    async getUsers(params: PaginationParams & GetUsersParams) {
      try {
        this.$patch({ listModule: { isLoading: true, error: null } })

        const { data: list, meta } = (await userApi.getUsers(params)).data

        this.$patch({
          listModule: {
            list,
            meta,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      } catch (e) {
        this.$patch({
          listModule: {
            error: parseErrors(e).error,
            isLoading: false,
            isInitialLoadingDone: true,
          },
        })
      }
    },

    async getUserDetails(id: string) {
      try {
        const usersDetails = this.usersDetails

        if (!usersDetails[id]) usersDetails[id] = getInitialUserDetailsState()

        this.$patch({
          usersDetails: { [id]: { isLoading: true, error: null } },
        })

        const data = (await userApi.getUserDetails(id)).data.data

        this.$patch({
          usersDetails: { [id]: { isLoading: false, data } },
        })
      } catch (e) {
        const { error } = parseErrors(e)
        this.$patch({
          usersDetails: { [id]: { isLoading: false, error } },
        })
      }
    },

    async updateUserStatus(payload: UpdateUserStatusPayload) {
      const { id, status } = payload

      try {
        if (!this.usersDetails[id]?.data) {
          throw new Error('User data must be received before status updating')
        }

        this.$patch({
          usersDetails: {
            [id]: { statusUpdating: { isLoading: true, error: null } },
          },
        })

        await userApi.updateUserStatus(payload.id, payload.status)

        this.$patch((state: State) => {
          const detailsModule = state.usersDetails[id]

          if (detailsModule?.data) {
            detailsModule.statusUpdating.isLoading = false
            detailsModule.data.status = status
          }

          const userInList = state.listModule.list.find(item => item.id === id)
          if (userInList) userInList.status = status
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch({
          usersDetails: {
            [id]: { statusUpdating: { isLoading: false, error } },
          },
        })

        ElMessage({ message: error, type: 'error', offset: 40 })
      }
    },

    async updateUser(userId: string, params: UpdateableUserParams) {
      const updatedKeys = Object.keys(params) as UserUpdateableKeys[]

      try {
        if (!this.usersDetails[userId]) {
          throw new Error('User data must be received before updating')
        }

        this.$patch(state => {
          const updtModule = state.usersDetails[userId]?.updating
          if (!updtModule) return

          handleMultipleUpdatesState('start', updtModule, updatedKeys)
        })

        const udpatedUser = (await userApi.updateUser(userId, params)).data.data

        this.$patch(state => {
          const detailsModule = state.usersDetails[userId]
          if (!detailsModule) return

          const updtModule = detailsModule.updating

          handleMultipleUpdatesState('success', updtModule, updatedKeys)
          detailsModule.data = { ...detailsModule.data, ...udpatedUser }
        })

        const message = getUpdatesSuccessMsg(updatedKeys)
        showToast({ type: 'success', message })
      } catch (e) {
        const { error, validationErrors } = parseErrors(e)

        this.$patch(state => {
          const updtModule = state.usersDetails[userId]?.updating
          if (!updtModule) return

          handleMultipleUpdatesState('error', updtModule, updatedKeys)
          updtModule.error = validationErrors || error
        })

        const message = getUpdatesErrorMsg(updatedKeys, e)
        showToast({ type: 'error', message })
      }
    },

    async verifyPaper(payload: PapersVerifyingPayload) {
      const { id, paper, value } = payload

      try {
        if (!this.usersDetails[id]?.data) {
          throw new Error('User data must be received before status updating')
        }

        this.$patch({
          usersDetails: {
            [id]: {
              papersVerifying: {
                [paper]: { isLoading: true, error: null } as CommonModule,
              },
            },
          },
        })

        await userApi.verifyPaper(payload)

        this.$patch({
          usersDetails: {
            [id]: {
              data: { [`${paper}_verified`]: value },
              papersVerifying: {
                [paper]: { isLoading: false } as CommonModule,
              },
            },
          },
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch({
          usersDetails: {
            [id]: {
              papersVerifying: {
                [paper]: { isLoading: false, error } as CommonModule,
              },
            },
          },
        })

        ElMessage({ message: error, type: 'error' })
      }
    },

    async resetPassword(id: string) {
      try {
        if (!this.usersDetails[id]?.data) {
          throw new Error('User data must be received before status updating')
        }

        this.$patch(state => {
          const passModule = state.usersDetails[id]?.passwordReset
          if (!passModule) return

          handleCommonModuleState('start', passModule)
        })

        await userApi.resetPassword(id)

        this.$patch(state => {
          const passModule = state.usersDetails[id]?.passwordReset
          if (!passModule) return

          handleCommonModuleState('success', passModule)
        })

        showToast({ type: 'success', message: 'Reset instructions sent.' })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const passModule = state.usersDetails[id]?.passwordReset
          if (!passModule) return

          handleCommonModuleState('error', passModule, error)
        })

        showToast({ type: 'error', message: error })
      }
    },

    async getUserTrips(userId: string, userType: UserType) {
      try {
        if (!this.userTrips[userId]) {
          this.userTrips[userId] = getInitialUserTripsState()
        }

        const tripsByType = (this.userTrips[userId] as UserTripsModule)[
          userType
        ]

        tripsByType.isLoading = true
        tripsByType.error = null

        const idKey = UserType.HOST === userType ? 'host_id' : 'renter_id'

        const params: PaginationParams & GetTripsParams = {
          page: 1,
          perPage: 10,
          sortBy: 'start_date',
          sortDirection: 'desc',
          [idKey]: userId,
        }

        const tripsGroups = await Promise.all([
          tripsApi.getTrips({ ...params, statuses: ONGOING_STATUSES }),
          tripsApi.getTrips({ ...params, statuses: UPCOMING_STATUSES }),
          tripsApi.getTrips({ ...params, statuses: PAST_RENTING_STATUSES }),
        ])

        const [ongoing, upcoming, pastRenting] = tripsGroups.map<TripGroup>(
          ({ data: { meta, data } }) => ({ list: data, total: meta.total })
        )

        tripsByType.isLoading = false
        tripsByType.data = { ongoing, upcoming, pastRenting }
      } catch (e) {
        if (!this.userTrips[userId]) return

        const { error } = parseErrors(e)

        const tripsByType = (this.userTrips[userId] as UserTripsModule)[
          userType
        ]

        tripsByType.error = error
        tripsByType.isLoading = false
      }
    },
    async getUserVehicles(userId: string) {
      try {
        if (!this.usersVehicles[userId]) {
          this.usersVehicles[userId] = getPagintaionModuleState()
        }

        this.$patch({
          usersVehicles: {
            [userId]: { isLoading: true, error: null },
          },
        })

        const params: PaginationParams & GetVehiclesParams = {
          page: 1,
          perPage: 10,
          sortDirection: 'desc',
          user_id: userId,
        }

        const {
          data: { meta, data: list },
        } = await vehiclesApi.getVehicles(params)

        this.$patch({
          usersVehicles: {
            [userId]: {
              meta,
              list,
              isLoading: false,
              isInitialLoadingDone: true,
            },
          },
        })
      } catch (e) {
        if (!this.usersVehicles[userId]) return

        const { error } = parseErrors(e)

        this.$patch({
          usersVehicles: {
            [userId]: { error, isLoading: false, isInitialLoadingDone: true },
          },
        })
      }
    },
    async getUserMisconductions(userId: string, pagination: PaginationParams) {
      try {
        if (!this.usersMisconductions[userId]) {
          this.usersMisconductions[userId] = getInitialMisconductsState()
        }

        this.$patch(state => {
          const listModule = state.usersMisconductions[userId]?.listModule

          if (listModule) handlePaginationModuleState('start', listModule)
        })

        const {
          data: { meta, data: list },
        } = await misconductionsApi.getUserMisconductions(userId, pagination)

        this.$patch(state => {
          const listModule = state.usersMisconductions[userId]?.listModule

          if (listModule) {
            handlePaginationModuleState('success', listModule, { meta, list })
          }
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const listModule = state.usersMisconductions[userId]?.listModule

          if (listModule) {
            handlePaginationModuleState('error', listModule, error)
          }
        })
      }
    },
    async createMisconduction(userId: string, comment: string) {
      try {
        if (!this.usersMisconductions[userId]) {
          throw new Error('Misconduction data should be retreived first.')
        }

        this.$patch(state => {
          const creation = state.usersMisconductions[userId]?.creation
          if (creation) handleCommonModuleState('start', creation)
        })

        const {
          data: { data },
        } = await misconductionsApi.createMisconduction(userId, comment)

        this.$patch(state => {
          const creation = state.usersMisconductions[userId]?.creation
          if (creation) handleCommonModuleState('success', creation)

          const list = state.usersMisconductions[userId]?.listModule.list
          if (list) list.unshift(data)
        })

        showToast({
          type: 'success',
          message: 'Comment was successfully added.',
        })
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state => {
          const creation = state.usersMisconductions[userId]?.creation
          if (creation) handleCommonModuleState('error', creation, error)
        })

        showToast({ type: 'error', message: `Error: ${error}` })
      }
    },
    async getUserInsuranceDetails(id: string) {
      try {
        const userInsurancePolicy = this.userInsurancePolicy

        if (!userInsurancePolicy[id]) {
          userInsurancePolicy[id] = getInitialUserInsuranceDetailsState()
        }
        this.$patch({
          userInsurancePolicy: { [id]: { isLoading: true, error: null } },
        })

        const data = (await userApi.getUserInsuranceDetails(id)).data.data

        this.$patch({
          userInsurancePolicy: { [id]: { isLoading: false, data } },
        })
      } catch (e) {
        const { error } = parseErrors(e)
        this.$patch({
          userInsurancePolicy: { [id]: { isLoading: false, error } },
        })
      }
    },
    async createUserInsuranceDetails(
      userId: string,
      payload: UserInsurancePolicy
    ) {
      try {
        this.$patch({
          userInsurancePolicy: {
            [userId]: { creatingLoading: true, success: false },
          },
        })

        const {
          data: { data: insurance },
        } = await userApi.createUserInsuranceDetails(userId, payload)

        this.$patch({
          userInsurancePolicy: {
            [userId]: {
              success: true,
              data: insurance,
            },
          },
        })

        showToast({
          type: 'success',
          message: 'Insurance was successfully created.',
        })
      } catch (e) {
        const { error } = parseErrors(e)
        showToast({ message: error, type: 'error' })
      } finally {
        this.$patch({
          userInsurancePolicy: {
            [userId]: { creatingLoading: false },
          },
        })
      }
    },
    async deleteUserPaymentCard(
      data: Parameters<typeof userApi.deleteUserPaymentCard>[0]
    ) {
      try {
        await userApi.deleteUserPaymentCard(data)

        const updatedCards = this.$state.usersDetails[
          data.userId
        ]?.data?.payment_cards.filter(c => c.id !== data.cardId)
        this.$patch({
          usersDetails: {
            [data.userId]: { data: { payment_cards: updatedCards } },
          },
        })
      } catch (error) {
        showToast({ message: 'Unable remove card', type: 'error' })
      }
    },
    async updateInsuranceDetails(
      userId: string,
      payload: Partial<UserInsurancePolicy>
    ) {
      const updatedKeys = Object.keys(payload) as UserInsuranceUpdatableKeys[]

      try {
        this.$patch(state => {
          const updatingModule = state.userInsurancePolicy[userId]?.updating

          if (!updatingModule) return

          updatingModule.error = null
          updatedKeys.forEach(key => {
            if (!updatingModule.loadingList.includes(key)) {
              updatingModule.loadingList.push(key)
            }

            const successIndex = updatingModule.successList.indexOf(key)

            if (successIndex !== -1) {
              updatingModule.successList.splice(successIndex, 1)
            }
          })
        })

        const {
          data: { data },
        } = await userApi.updateUserInsuranceDetails(userId, payload)

        this.$patch(state => {
          const insuranceDetailsModule = state.userInsurancePolicy[userId]
          if (!insuranceDetailsModule) return

          insuranceDetailsModule.data = data

          const updatingModule = insuranceDetailsModule.updating

          updatedKeys.forEach(key => {
            if (!updatingModule.successList.includes(key)) {
              updatingModule.successList.push(key)
            }
          })
        })

        const [[fieldName, value]] = Object.entries(payload)
        showToast({
          type: 'success',
          message: `${commonCase(
            fieldName,
            true
          )} - ${value} was successfully updated.`,
        })
      } catch (e) {
        const { error } = parseErrors(e)
        showToast({ message: error, type: 'error' })
      } finally {
        this.$patch((state: State) => {
          const updatingModule = state.userInsurancePolicy[userId]?.updating

          if (!updatingModule) return

          updatedKeys.forEach(key => {
            const loadingIndex = updatingModule.loadingList.indexOf(key)
            if (loadingIndex != -1) {
              updatingModule.loadingList.splice(loadingIndex, 1)
            }
          })
        })
      }
    },
  },
})
